import request from '@/plugins/axios'

/** S 商城列表 **/
// 商城列表
export const apiManagerLists = (params: any) => request.get('/manager.manager/lists', { params })

// 添加商城
export const apiManagerAdd = (params: any) => request.post('/manager.manager/add', params)

// 商城详情apiManagerDetail
export const apiManagerDetail = (params: any) => request.get('/manager.manager/detail', { params })

// 编辑商城
export const apiManagerEdit = (params: any) => request.post('/manager.manager/edit', params)

// 删除商城
export const apiManagerDelete = (params: any) => request.post('/manager.manager/delete', params)

// 修改超级管理员
export const apiManagerChangeSuperAdmin = (params: any) => request.post('/manager.manager/changeSuperAdmin', params)

// 修改商城状态
export const apiManagerSwitchStatus = (params: any) => request.post('/manager.manager/switchStatus', params)


// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/manager.manager/setMealLogLists', { params })

// 修改商城备注
export const apiChangeRemark = (params: any) => request.post('/manager.manager/changeRemark', params)
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) => request.get('/manager.set_meal/lists', { params })

// 添加套餐
export const apiSetMealAdd = (params: any) => request.post('/manager.set_meal/add', params)

// 套餐详情
export const apiSetMealDetail = (params: any) => request.get('/manager.set_meal/detail', { params })

// 编辑套餐
export const apiSetMealEdit = (params: any) => request.post('/manager.set_meal/edit', params)

// 删除套餐
export const apiSetMealDelete = (params: any) => request.post('/manager.set_meal/del', params)

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) => request.post('/manager.set_meal/status', params)

export const apiMealModule = () => request.get('/manager.set_meal/getMealModule')
/** E 套餐列表 **/

